import React, { useEffect } from 'react';
import OpenSeadragon from 'openseadragon';

function OpenSeadragonViewer() {
  useEffect(() => {
    console.log('inside sea dragon')
    const viewer = OpenSeadragon({
      id: 'openseadragon1',
      prefixUrl: '/openseadragon/build/images/',
      tileSources: 'https://wjewdc-images-bucket-2023.s3.amazonaws.com/TILES/metro_test/wmata2.dzi'
    });
  }, []);

  return (
    <div id="openseadragon1" style={{ width: '800px', height: '600px' }} />
  );
}

export default OpenSeadragonViewer;
