import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { useJwt } from "react-jwt";
const REACT_DJANGO = process.env.REACT_APP_DJANGO

function GraphData(props) {
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({});

  var userData = JSON.parse(localStorage.getItem('userInfo'))
  const token = userData.token;
  const { decodedToken, isExpired } = useJwt(token);

  useEffect(() => {
    async function fetchData() {
      let url = REACT_DJANGO +props.url//'/api/wmata/gage/'

      //Get authorization token
      
      const token = userData.token;

      //console.log(token, decodedToken, isExpired)

      const response = await fetch(url,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':'Bearer ' + token
        },
        body: JSON.stringify({
          'gagelist': props.gagelist,
          'dateRange': props.dateRange,
          'config': props.config
        })
      });
      
      const info = await response.json();
      setData(info.data);
      setLayout(info.layout);
    }
    fetchData();
  }, [props.gagelist, props.dateRange, props.config]);

  return (
    <Plot
      data={data}
      layout={layout}
    />
  );
}

export default GraphData;
