import React from 'react'
import { useParams } from 'react-router-dom'
import PlotWmataTS from '../../components/plots/PlotWMATA'
import NavbarBoot from '../../components/nav/NavbarNew'
// import GraphDanzigerTS from '../../components/plots/PlotDanziger'
// import Spinner from '../components/layout/Spinner'

function Plots() {
  const { duration } = useParams()
  const today = new Date()
  // const start = new Date(today)
  const start = new Date('2022-08-01T00:00:00');

  let days = 120

  if (duration != null) {
    days = duration
  }

  // start.setDate(start.getDate() - days)
  start.setDate(start.getDate() )
  today.setDate(today.getDate() + 1)

  const start_time = start.toISOString().split('T')[0] + ' 00:00:00'
  const end_time = today.toISOString().split('T')[0] + ' 00:00:00'

  return (
    <><NavbarBoot />
    <div>
      <div>
      <h2 className='bg-slate-300 text-2xl pl-5'>WMATA B-Line</h2>
      <h6 className='bg-slate-300 text-xs pl-5'>Temperature & Humidity</h6>

      <PlotWmataTS
        gagelist={{
          primary: [
            {
              location: 'B-S_523.00',
              name: 'B-S_523.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'B-1_498.00',
              name: 'B-1_498+00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'B-2_489.00',
              name: 'B-2_489.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'B-1_515.00',
              name: 'B-1_515.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'B-2_520.00',
              name: 'B-2_520.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'B-2_524.50',
              name: 'B-2_524.50',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'B-2_534.00',
              name: 'B-2_534.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'FG_elec',
              name: 'FG_elec',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'B-S_548.00',
              name: 'B-S_548.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'temp',
              name: 'Surface temp',
              sensortype: 'temp',
              table: "weather"
            },

          ],

          y_range: [0, 70],
          title: 'B-Line Temperatures',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axis_label: 'Temperature (&#176;F)',
          y_axis_label2: '',
        }}
      />

<PlotWmataTS
        gagelist={{
          primary: [
            {
              location: 'B-S_523.00',
              name: 'B-S_523.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'B-1_498.00',
              name: 'B-1_498.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'B-2_489.00',
              name: 'B-2_489.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'B-1_515.00',
              name: 'B-1_515.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'B-2_520.00',
              name: 'B-2_520.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'B-2_524.50',
              name: 'B-2_524.50',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'B-2_534.00',
              name: 'B-2_534.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'FG_elec',
              name: 'FG_elec',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'B-S_548.00',
              name: 'B-S_548.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            
          ],

          y_range: [0, 70],
          title: 'B-Line Humidity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axis_label: 'Humidity (%)',
          y_axis_label2: '',
        }}
      />

  <h6 className='bg-slate-300 text-xs pl-5'>B-Line Wetness</h6>
  <PlotWmataTS
        gagelist={{
          primary: [
            {
              location: 'B-1_498.01',
              name: 'B-1_498.01',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'B-1_528.00',
              name: 'B-1_528.00',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'B-2_520.00',
              name: 'B-2_520.00',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'B-2_523.50',
              name: 'B-2_523.50',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'B-2_531.00',
              name: 'B-2_531.00',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'B-2_534.00',
              name: 'B-2_534.00',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },

            {
              location: "B-2_490.00",
              name: "B-2_490.00",
              sensortype: "wetness",
              table: "leap_wet_location",
            },
            {
              location: "B-2_495.50",
              name: "B-2_495.50",
              sensortype: "wetness",
              table: "leap_wet_location",
            },

          ],
          secondary: [
            {
              location: 'prcp',
              name: 'Prcp',
              sensortype: 'prcp',
              table: "weather"
            },],

          y_range: [0, 70],
          title: 'B-Line Wetness',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axis_label: 'Wetness (%)',
          y_axis_label2: '',
        }}
        />
    </div>

    <div>
      <h2 className='bg-slate-300 text-2xl pl-5'>WMATA A-Line</h2>
      <h6 className='bg-slate-300 text-xs pl-5'>Temperature & Humidity</h6>

      <PlotWmataTS
        gagelist={{
          primary: [
            {
              location: 'A-1_293.00',
              name: 'A-1_293.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'A-1_278.00',
              name: 'A-1_278.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'A-1_266.00',
              name: 'A-1_266.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'A-2_283.00',
              name: 'A-2_283.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'A-2_294.50',
              name: 'A-2_294.50',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'A-S_264+00',
              name: 'A-S_264+00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'A-S_297+00',
              name: 'A-S_297+00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'A-S_298.50',
              name: 'A-S_298.50',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
            {
              location: 'A-S-265.00',
              name: 'A-S-265.00',
              sensortype: 'temperature',
              table: "leap_th2_location"
            },
          ],

          y_range: [0, 70],
          title: 'A-Line Temperatures',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axis_label: 'Temperature (&#176;F)',
          y_axis_label2: '',
        }}
      />

<PlotWmataTS
        gagelist={{
          primary: [

            {
              location: 'A-1_293.00',
              name: 'A-1_293.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'A-1_278.00',
              name: 'A-1_278.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'A-1_266.00',
              name: 'A-1_266.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'A-2_283.00',
              name: 'A-2_283.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'A-2_294.50',
              name: 'A-2_294.50',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'A-S_264+00',
              name: 'A-S_264+00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'A-S_297+00',
              name: 'A-S_297+00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'A-S_298.50',
              name: 'A-S_298.50',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
            {
              location: 'A-S_265.00',
              name: 'A-S_265.00',
              sensortype: 'humidity',
              table: "leap_th2_location"
            },
          ],

          y_range: [0, 70],
          title: 'A-Line Humidity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axis_label: 'Humidity (%)',
          y_axis_label2: '',
        }}
      />
  <h6 className='bg-slate-300 text-xs pl-5'>Wetness</h6>
<PlotWmataTS
        gagelist={{
          primary: [
            {
              location: 'A-1_293.00',
              name: 'A-1_293.00',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'A-1_281.00',
              name: 'A-1_281.00',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'A-2_290.00',
              name: 'A-2_290.00',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'A-2_286.00',
              name: 'A-2_286.00',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'A-2_294.50',
              name: 'A-2_294.50',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },
            {
              location: 'A-S_277.00',
              name: 'A-S_277.00',
              sensortype: 'wetness',
              table: "leap_wet_location"
            },

          ],
          secondary: [
            {
              location: 'prcp',
              name: 'Prcp',
              sensortype: 'prcp',
              table: "weather"
            },],

          y_range: [0, 70],
          title: 'A-Line Wetness',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axis_label: 'Wetness (%)',
          y_axis_label2: '',
        }}
        />
    </div>
    
    </div></>
  )
}

export default Plots
