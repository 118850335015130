import React from "react"
import NavbarBoot from "../../components/nav/NavbarNew"
import { useStatusData } from "../../hooks/useStatusData"

import { Link } from "react-router-dom"
// import { useAuthContext } from '../../hooks/useAuthContext'
import GraphicB from "../../components/graphic/GraphicWMATAlineB"
import GraphicA from "../../components/graphic/GraphicWMATAlineA"

export default function Dashboard() {

  var userData = JSON.parse(localStorage.getItem('userInfo'))
  //var userData = JSON.parse(sessionStorage.getItem('userInfo'))

  const { data, isLoading, error } = useStatusData()
  // const { user, authIsReady } = useAuthContext()
  // console.log('dashboard',user)
  // console.log("dash", data)

  if (isLoading) {
    return <h3>Loading...</h3>
  }
  return (
    <>
      <NavbarBoot />
      <div className="flex flex-col justify-between ">
        <main className="container mx-auto px-3 pb-12">
          {/* <div>
            <Link to="/status">Status Page</Link>
          </div> */}
          <h2 className='bg-slate-300 text-2xl pl-5'>WMATA B-Line</h2>
          <GraphicB data={data} />
          <h2 className='bg-slate-300 text-2xl pl-5'>WMATA A-Line</h2>
          <GraphicA data={data} />
        </main>
      </div>
    </>
  )
}
