// import { useAuthContext } from "./hooks/useAuthContext"

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom"

import Login from "./pages/login/Login"
import Dashboard from "./pages/dashboard/dashboard"
import Status from "./pages/status/Status"
import Plots from './pages/plots/Plots'
import Plots2wk from './pages/plots/Plots2wk'
import PlotSurf2wk from './pages/plots/PlotSurf2wk'
import PlotDews2 from './pages/plotDews/PlotDews2'
import PlotDews2wk from './pages/plotDews/PlotDews2wk'
import PlotFlow2wk from './pages/plotflow/PlotFlow2wk'
import PlotFlow from "./pages/plotflow/PlotFlow"
import Image from "./pages/image/Image"
import HiRes from "./pages/highres/highres"

function App() {
  var userData = JSON.parse(localStorage.getItem('userInfo'))
  //var userData = JSON.parse(sessionStorage.getItem('userInfo'))

  //if userData.access is null console log "no access" otherwise console log "access"
  userData?.access ? console.log('access') : console.log('no access')



  return (
    <Router>
      <Routes>
        {(userData?.access) ? (
          <>
            <Route  path="/dashboard" element={<Dashboard />} />
            <Route path="/status" element={<Status />} />
            <Route path="/plots" element={<Plots />} />
            <Route path="/plots2wk" element={<Plots2wk />} />
            <Route path="/plotsurf2wk" element={<PlotSurf2wk />} />
            <Route path="/plotdews" element={<PlotDews2 />} />
            <Route path="/plotdews2wk" element={<PlotDews2wk />} />
            <Route path="/plotflows" element={<PlotFlow />} />
            <Route path="/plotflows2wk" element={<PlotFlow2wk />} />
            <Route path="/imagetest" element={<Image />} />
            <Route path="/highres" element={<HiRes />} />
            <Route path="/login" element={<Login />} />
            <Route exact path="/" element={<Dashboard />} />
          </>
        ) : (
          <Route exact path="/" element={<Login />} />
        )}
      </Routes>
    </Router>
  )
}

export default App

