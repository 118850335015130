import { useState, useEffect } from "react"
// import { projectAuth, projectFirestore } from '../firebase/config'
import { useAuthContext } from './useAuthContext'
import { useNavigate } from 'react-router-dom';

const DJANGO_URL = process.env.REACT_APP_DJANGO

export const useLogin = () => {
  const navigate = useNavigate();
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const {  authIsReady,loginUser } = useAuthContext()

  const login = async (username, password) => {
    setError(null)
    setIsPending(true)
    loginUser(username,password)
    navigate('/status', { replace: true })
    if(authIsReady){
    navigate('/status', { replace: true })
  } else {navigate('/', { replace: true }) }

  }


  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { login, isPending, error }
}
