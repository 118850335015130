import NavbarBoot from "../../components/nav/NavbarNew"

import { render } from "@testing-library/react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro"
import dayjs from "dayjs"

import { useAuthContext } from '../../hooks/useAuthContext'

const REACT_DJANGO = process.env.REACT_APP_DJANGO



const Image = () => {




    return (
      <>
      <NavbarBoot />
<img src="https://wjewdc-images-bucket-2023.s3.amazonaws.com/dilbert_software.PNG" alt="Dilbert software cartoon"/>


      </>
    )
  
}

export default Image
