import React from 'react'
import { useParams } from 'react-router-dom'
import PlotData from '../../components/plots/PlotData'
import NavbarBoot from '../../components/nav/NavbarNew'
// import GraphDanzigerTS from '../../components/plots/PlotDanziger'
// import Spinner from '../components/layout/Spinner'

function PlotFlow() {
  const { duration } = useParams()
  const today = new Date()
  // const start = new Date(today)
  const start = new Date('2022-08-01T00:00:00');

  let days = 180

  if (duration != null) {
    days = duration
  }

  // start.setDate(start.getDate() - days)
  start.setDate(start.getDate() )
  today.setDate(today.getDate() + 1)

  const start_time = start.toISOString().split('T')[0] + ' 00:00:00'
  const end_time = today.toISOString().split('T')[0] + ' 00:00:00'

  return (
    <><NavbarBoot />
    <div>
      <div>
      <h2 className='bg-slate-300 text-2xl pl-5'>WMATA Tunnels</h2>
      <h6 className='bg-slate-300 text-xs pl-5 pb-2'>B-Line Flow</h6>

      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'B-1_523.00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_flow_location",
              scalar:'39.37',
              tare:'0.0'
            },
            

          ],
          secondary: [
            {
              location: 'B-1_523.00',
              name: 'Velocity',
              sensortype: 'velocity',
              table: "leap_flow_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'B-1_523.00 Level and Velocity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 4.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Level (in.)',
          y_axis_label2: 'Velocity (ft/s)',
        }}
      />
      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'B-1_523.00',
              name: 'GPM',
              sensortype: 'flow',
              table: "leap_flow_location",
            },
            
          ],
          secondary: [
            {
              location: 'B-1_523.00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_flow_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'B-1_523.00 GPM',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 40.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Flow (GPM)',
          y_axis_label2: 'Level (in.)',
        }}
      />
      <h6 className=''>B-2 Flow</h6>      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'B-2_523.00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_flow_location",
              scalar:'39.37',
              tare:'0.0'
            },
            

          ],
          secondary: [
            {
              location: 'B-2_523.00',
              name: 'Velocity',
              sensortype: 'velocity',
              table: "leap_flow_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'B-2_523.00 Level and Velocity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 4.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Level (in.)',
          y_axis_label2: 'Velocity (ft/s)',
        }}
      />
      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'B-2_523.00',
              name: 'GPM',
              sensortype: 'flow',
              table: "leap_flow_location",
            },
            
          ],
          secondary: [
            {
              location: 'B-2_523.00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_flow_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'B-1_523.00 GPM',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 40.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Flow (GPM)',
          y_axis_label2: 'Level (in.)',
        }}
      />
      <h6 className='bg-slate-300 text-xs pl-5 pb-2'>A-Line Flow</h6>

      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'A-1_296+00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_flow_location",
              scalar:'39.37',
              tare:'0.0'
            },
            

          ],
          secondary: [
            {
              location: 'A-1_296+00',
              name: 'Velocity',
              sensortype: 'velocity',
              table: "leap_flow_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'A-1_296+00 Level and Velocity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 4.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Level (in.)',
          y_axis_label2: 'Velocity (ft/s)',
        }}
      />
            <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'A-1_296+00',
              name: 'GPM',
              sensortype: 'flow',
              table: "leap_flow_location",
            },
            
          ],
          secondary: [
            {
              location: 'A-1_296+00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_flow_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'A-1_296+00 GPM',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 40.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Flow (GPM)',
          y_axis_label2: 'Level (in.)',
        }}
      />
      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'A-2_298.00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_flow_location",
              scalar:'39.37',
              tare:'0.0'
            },
            

          ],
          secondary: [
            {
              location: 'A-2_298.00',
              name: 'Velocity',
              sensortype: 'velocity',
              table: "leap_flow_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'A-2_298.00 Level and Velocity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 4.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Level (in.)',
          y_axis_label2: 'Velocity (ft/s)',
        }}
      />
            <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'A-2_298.00',
              name: 'GPM',
              sensortype: 'flow',
              table: "leap_flow_location",
            },
            
          ],
          secondary: [
            {
              location: 'A-2_298.00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_flow_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'A-2_298.00 GPM',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 40.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Flow (GPM)',
          y_axis_label2: 'Level (in.)',
        }}
      />



    </div>
    
    </div></>
  )
}

export default PlotFlow
