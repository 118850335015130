import React, { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import PlotData from '../../components/plots/PlotData'
import NavbarBoot from '../../components/nav/NavbarNew'
import DatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import GraphData from "../../components/plots/PlotData" // Update the import


function PlotDews2wk() {
  const { duration } = useParams();
  const today = new Date();
  const start = new Date();

  let days = 14;
  if (duration != null) {
    days = parseInt(duration, 10);
  }
  start.setDate(today.getDate() - days);

  const [tempDateRange, setTempDateRange] = useState({
    from_date: moment(start).format("YYYY-MM-DD"),
    to_date: moment(today).format("YYYY-MM-DD"),
  });

  const [dateRange, setDateRange] = useState({ ...tempDateRange });

  useEffect(() => {
    setDateRange({ ...tempDateRange });
  }, [tempDateRange]);

  const handleStartDateChange = (date) => {
    setTempDateRange({
      ...tempDateRange,
      from_date: date ? moment(date).format("YYYY-MM-DD") : tempDateRange.from_date,
    });
  };

  const handleEndDateChange = (date) => {
    setTempDateRange({
      ...tempDateRange,
      to_date: date ? moment(date).format("YYYY-MM-DD") : tempDateRange.to_date,
    });
  };

  const handleDateUpdate = () => {
    const daysDifference = moment(tempDateRange.to_date).diff(
      moment(tempDateRange.from_date),
      "days"
    );
    if (daysDifference >= 0 && daysDifference <= 21) {
      setDateRange(tempDateRange);
    } else {
      alert("Please select a date range not exceeding 21 days.");
    }
  };

  const start_time = dateRange.from_date + " 00:00:00";
  const end_time = dateRange.to_date + " 00:00:00";

  return (
    <>
      <NavbarBoot />
      <div>
        <div>
          <h2 className="bg-slate-300 text-2xl pl-5">WMATA B-Line</h2>
          <h6 className="bg-slate-300 text-xs pl-5">Temperature & Humidity</h6>
          <div className="date-picker-container">
            <DatePicker
              selected={new Date(tempDateRange.from_date)}
              onChange={handleStartDateChange}
              maxDate={new Date()}
            />
            <DatePicker
              selected={new Date(tempDateRange.to_date)}
              onChange={handleEndDateChange}
              maxDate={new Date()}
            />
            <button onClick={handleDateUpdate}>Update Date Range</button>
          </div>
      <h2 className='bg-slate-300 text-2xl pl-5'>WMATA Tunnels</h2>
      <h6 className='bg-slate-300 text-xs pl-5 pb-2'>Temperature & Humidity</h6>

      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'B-S_523.00',
              name: 'B-S_523.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'B-1_498.00',
              name: 'B-1_498+00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'B-2_489.00',
              name: 'B-2_489.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'B-1_515.00',
              name: 'B-1_515.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'B-2_520.00',
              name: 'B-2_520.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'B-2_524.50',
              name: 'B-2_524.50',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'B-2_534.00',
              name: 'B-2_534.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'FG_elec',
              name: 'FG_elec',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'dew_pt',
              name: 'Surface dew pt.',
              sensortype: 'dew_pt',
              table: "weather"
            },

          ],
          secondary: [
            {
              location: 'prcp',
              name: 'Prcp',
              sensortype: 'prcp',
              table: "weather"
            },],

          y_range: [0, 70],
          title: 'B-Line Dew Point Temperatures',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axes_range2: [0.0, 2.0],
          y_axis_label: 'Dew Pt. Temperature (&#176;F)',
          y_axis_label2: 'Precipitation (in.)',
        }}
      />

<PlotData
url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'A-1_293.00',
              name: 'A-1_293.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'A-1_278.00',
              name: 'A-1_278.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'A-1_266.00',
              name: 'A-1_266.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'A-2_283.00',
              name: 'A-2_283.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'A-2_294.50',
              name: 'A-2_294.50',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'A-S_264+00',
              name: 'A-S_264+00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'A-S_297+00',
              name: 'A-S_297+00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'A-S_298.50',
              name: 'A-S_298.50',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'A-S_265.00',
              name: 'A-S_265.00',
              sensortype: 'dewpt',
              table: "leap_th1_location"
            },
            {
              location: 'dew_pt',
              name: 'Surface dew pt.',
              sensortype: 'dew_pt',
              table: "weather"
            },

          ],
          secondary: [
            {
              location: 'prcp',
              name: 'Prcp',
              sensortype: 'prcp',
              table: "weather"
            },],

          y_range: [0, 70],
          title: 'A-Line Dew Point Temperatures',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axes_range2: [0.0, 2.0],
          y_axis_label: 'Dew Pt. Temperature (&#176;F)',
          y_axis_label2: 'Precipitation (in.)',
        }}
      />
      <h6 className='bg-slate-300 text-xs pl-5 pt-2 pb-2'> Wetness</h6>
  <PlotData
  url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'B-1_498.01',
              name: 'B-1_498.01',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            {
              location: 'B-1_528.00',
              name: 'B-1_528.00',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            
            {
              location: 'B-2_520.00',
              name: 'B-2_520.00',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            {
              location: 'B-2_523.50',
              name: 'B-2_523.50',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            {
              location: 'B-2_531.00',
              name: 'B-2_531.00',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            {
              location: 'B-2_534.00',
              name: 'B-2_534.00',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },

          ],
          secondary: [
            {
              location: 'prcp',
              name: 'Prcp',
              sensortype: 'prcp',
              table: "weather"
            },],

          y_range: [0, 70],
          title: 'B-Line Wetness',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axes_range2: [0.0, 2.0],
          y_axis_label: 'Wetness (%)',
          y_axis_label2: 'Precipitation (in.)',
        }}
        />

<PlotData
url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'A-1_293.00',
              name: 'A-1_293.00',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            {
              location: 'A-1_281.00',
              name: 'A-1_281.00',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            {
              location: 'A-2_290.00',
              name: 'A-2_290.00',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            {
              location: 'A-2_286.00',
              name: 'A-2_286.00',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            {
              location: 'A-2_294.50',
              name: 'A-2_294.50',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
            {
              location: 'A-S_277.00',
              name: 'A-S_277.00',
              sensortype: 'wetness',
              table: "leap_wet1_location"
            },
          ],
          secondary: [
            {
              location: 'prcp',
              name: 'Prcp',
              sensortype: 'prcp',
              table: "weather"
            },],

          y_range: [0, 70],

          title: 'A-Line Wetness',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 100],
          y_axes_range2: [0.0, 2.0],
          y_axis_label: 'Wetness (%)',
          y_axis_label2: 'Precipitation (in.)',
        }}
        />


    </div>
    
    </div></>
  )
}

export default PlotDews2wk
