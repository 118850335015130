import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import { useJwt } from "react-jwt";
import { useNavigate } from 'react-router-dom';


// import { useAuthContext } from '../../hooks/useAuthContext'

const REACT_DJANGO = process.env.REACT_APP_DJANGO

let url = REACT_DJANGO + "/api/wmata/status/"

export const useStatusData = () => {
    // const {user} = useAuthContext()
    const navigate = useNavigate();
    const [error, setError] = useState(null)
    const [data, setData] = useState({})
    const [isLoading, setLoading] = useState(true)


    console.log('inside status')
    // console.log(user)
    var userData = JSON.parse(localStorage.getItem('userInfo'))
    const token = userData.token;
    const { decodedToken, isExpired } = useJwt(token);


    useEffect(() => {

      userData.access ? console.log('useStatus access') : console.log('useStatus no access')
      console.log('useStatus:', isExpired)
      if (isExpired) {
        // localStorage.removeItem('userInfo')
        setError("Token expired")
        navigate('/login', { replace: true })
      }


        const getStatus = async () => {
          
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              'Authorization':'Bearer '+userData.token
            },
          })
    
          const resopnseJson = await response.json()
          console.log("json", resopnseJson)
          setData(resopnseJson)
          setLoading(false)
        }
        if (!userData.access) {
          setLoading(true)
          setError("No access")
        }
        else {
          console.log('inside else')
          getStatus()
        }
      }, [])

  return { data, isLoading, error }
}
