// import { FaWaveSquare } from 'react-icons/fa'
import { Link } from "react-router-dom"
import { Fragment, useState, useContext } from "react"

import { useAuthContext } from "../../hooks/useAuthContext"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  solid,
  regular,
  brands,
  duotone,
} from "@fortawesome/fontawesome-svg-core/import.macro"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid"
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline"
// import MenuDanziger from './MenuDanziger'
// import MenuSeismo from './MenuSeismo'
// import MenuLuling from './MenuLuling'
// import MenuNY17 from './MenuNY17'

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { Waveform } from '@fortawesome/pro-duotone-svg-icons';
const navigation = [
  { name: "About", href: "/about", current: false },
  { name: "Status", href: "/status", current: false },
]

const userNavigation = [
  { name: "Your Profile", href: "/", action: "logout()" },
  { name: "Settings", href: "/", action: "logout()" },
  { name: "Sign out", href: "/", action: "logout()" },
]

function logout() {
  localStorage.removeItem("userInfo")
  console.log("logout ran")
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

function NavbarBoot({ title }) {
  // const { userInfo } = useContext(UserContext)
  const { user, authIsReady } = useAuthContext()

  //Setup user menu based on groups they belong too
  var userData = JSON.parse(localStorage.getItem("userInfo"))
  //var userData = JSON.parse(sessionStorage.getItem('userInfo'))
  var groups = ['danziger']

  // if (userData !== null) {
  //   groups = userData.groups
  //   //console.log(`groups ${groups}`);
  // }

  // console.log(groups)

  return (
    // <nav className='navbar mb-12 shadow-lg bg-neutral text-neutral-con    <Navbar collapseOnSelect expand='lg' bg='dark' variant='dark'>
    <Disclosure as="header" className="bg-gray-800 z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
            <div className="relative h-16 flex justify-between">
              <div className="relative z-10 px-2 flex lg:px-0">
                <div className="flex-shrink-0 flex items-center text-white">
                  <FontAwesomeIcon icon={solid("waveform")} size="lg" />
                  <Link to="/" className="text-lg font-bold align-middle">
                    &nbsp; {title}
                  </Link>
                  <p> &nbsp;  &nbsp; </p>
                  <img src="Jacobs_logo_rgb_white.png" alt="Jacobs" width="70" height="70"/>
                </div>

              </div>

              <div className="relative z-10 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:justify-items-center">
                {/* <button
                  type='button'
                  className='bg-gray-800 flex-shrink-0 rounded-full p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
                >
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                </button> */}

                {/* Profile dropdown */}
                <Menu as="div" className="flex-shrink-0 relative ml-4 ">
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                      {localStorage.getItem("userInfo") ? (
                        userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-sm text-gray-700"
                                )}
                                onClick={logout()}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))
                      ) : (
                        <a href="/login">Login</a>
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>
                {userData?.access ? (
                  <Menu as="div" className="flex-shrink-0 relative justify-end">
                  <>
                    <div>
                      <Menu.Button className="bg-white-800 flex  text-gray-300 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white px-3 py-2 mt-4 rounded-md text-sm font-medium">
                        <span className="sr-only">Open user menu</span>
                        Plots &nbsp;
                        <div className="items-center">
                          <FontAwesomeIcon
                            icon={solid("square-chevron-down")}
                          />
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-left right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Dashboard
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/plotdews"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Dewpoints & Wetness
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/plotflows"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Flow Measurement
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/plots"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Temperature & Humidity
                            </a>
                            
                          )}
                        </Menu.Item>
                        <div className="border-t border-gray-700 pt-1 "></div>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/plots2wk"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Temperature & Humidity 2-Wk Detail
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/plotdews2wk"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Dewpoints & Wetness 2-Wk Detail
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/plotsurf2wk"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Surface Gauges 2-Wk Detail
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/plotflows2wk"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Flow Gauges 2-Wk Detail
                            </a>
                          )}
                        </Menu.Item>


                      </Menu.Items>
                    </Transition>
                    
                    </> </Menu>) : (
                      ""
                    )}

                
              </div>

              <nav
                className="hidden items-center justify-items-start lg:py-2 lg:flex lg:space-x-8 "
                aria-label="Global"
              >
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "rounded-md py-2 px-3 inline-flex items-center text-sm font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </a>
                ))}

                {/* Banner menu items */}
                {/* {groups.includes('danziger') ?<MenuDanziger />: '' }
                {groups.includes('luling') ?<MenuLuling />: '' }
                {groups.includes('seismo') ?<MenuSeismo />: '' }
                {groups.includes('ny17') ?<MenuNY17 />: '' } */}

                <Menu as="div" className="flex-shrink-0 relative ml-4">
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <Menu.Button className="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      {localStorage.getItem("userInfo") ? (
                        <>
                          <FontAwesomeIcon icon={solid("user")} size="lg" />
                          {userData.email}
                        </>
                      ) : (
                        <a href="/">
                          <FontAwesomeIcon
                            icon={solid("user-slash")}
                            size="lg"
                          />
                          Not logged in
                        </a>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                      {localStorage.getItem("userInfo") ? (
                        userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-sm text-gray-700"
                                )}
                                onClick={logout()}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))
                      ) : (
                        <a href="/login">Login</a>
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </nav>
            </div>
          </div>

          <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="pt-2 pb-3 px-2 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md py-2 px-3 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>

            {/* Hamburger menu for Danziger */}
            <div className="border-t border-gray-700 pt-4 pb-3">
              {groups.includes("danziger") ? (
                <div className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                  <a href="/plotdews">Plots</a>
                </div>
              ) : (
                ""
              )}

              {groups.includes("danziger") ? (
                <div className="ml-5">
                  <div className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                    <a href="/">Dashboard</a>
                  </div>

                  <div className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                    <a href="/plotdews">Dew Pt Plots</a>
                  </div>

                  <div className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                    <a href="/plotflows">Flow Plots</a>
                  </div>

                  <div className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                    <a href="/plots">Temp & Humidity</a>
                  </div>
                  <div className="border-t border-gray-700 pt-4 pb-3"></div>
                  <div className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                    <a href="/plots2wk">Temp & Humidity 2-Wk Detail</a>
                  </div>
                  <div className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                    <a href="/plotdews2wk">Dew Pt Plots 2-Wk Detail</a>
                  </div>
                  <div className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                    <a href="/plotsurf2wk">Surface temp Plots 2-Wk Detail</a>
                  </div>
                  <div className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
                    <a href="/plotflows2wk">Flow Plots 2-Wk Detail</a>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* Hamburger menu for luling */}
              <div className="border-t border-gray-700 pt-4 pb-3">
                {/* {groups.includes('luling') ?(
              <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                <a href='/luling'>Luling</a>
              </div>) : '' } */}

                {/* {groups.includes('luling') ?(
              <div className='ml-5'>
                <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                  <a href='/luling/7'>Past 7-days</a>
                </div>

                <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                  <a href='/luling/28'>Past 28-day</a>
                </div>

                <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                  <a href='/luling/56'>Past 56-day</a>
                </div>
              </div>) : '' } */}

                {/* Hamburger menu for luling */}
                {/* <div className='border-t border-gray-700 pt-4 pb-3'>
            {groups.includes('ny17') ?(
              <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                <a href='/ny17'>NY17</a>
              </div>) : '' }

              {groups.includes('ny17') ?(
              <div className='ml-5'>
                <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                  <a href='/ny17'>Past 7-days</a>
                </div>

                <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                  <a href='/luling/28'>Past 28-day</a>
                </div>

                <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                  <a href='/luling/56'>Past 56-day</a>
                </div>
              </div>) : '' } */}

                {/* Hamburger menu for seismographs */}
                <div className="border-t border-gray-700 pt-4 pb-3">
                  {/* {groups.includes('seismo1') ?(
                <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                  <a href='#'>Seismographs</a>
                </div>) : '' } */}

                  <div className="ml-5">
                    {/* {groups.includes('gab') ?(
                  <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                    <a href='/GAB/7'>GAB</a>
                  </div>): '' } */}

                    {/* {groups.includes('prince') ?(
                  <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                    <a href='/seismoPrince'>Prince St</a>
                  </div>): '' } */}

                    {/* {groups.includes('n40a') ?(
                  <div className='block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'>
                    <a href='/n40a'>N40a</a>
                  </div>): '' } */}
                  </div>
                </div>

                <div className="border-t border-gray-700 pt-4 pb-3">
                  <div className="px-4 flex items-center">
                    <div className="flex-shrink-0 text-white">
                      <FontAwesomeIcon icon={solid("user")} size="lg" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">
                        {localStorage.getItem("userInfo") ? (
                          userData.name
                        ) : (
                          <a href="/">Not logged in</a>
                        )}
                      </div>
                      <div className="text-sm font-medium text-gray-400">
                        {localStorage.getItem("userInfo") ? (
                          userData.email
                        ) : (
                          <a href="/">Not logged in</a>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md py-2 px-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

NavbarBoot.defaultProps = {
  title: "WJE Analytics",
}

NavbarBoot.propTypes = {
  title: PropTypes.string,
}

export default NavbarBoot
