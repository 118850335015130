import React, { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import PlotData from '../../components/plots/PlotData'
import NavbarBoot from '../../components/nav/NavbarNew'
// import GraphDanzigerTS from '../../components/plots/PlotDanziger'
// import Spinner from '../components/layout/Spinner'
import DatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"



function PlotFlow2wk() {
  const { duration } = useParams();
  const today = new Date();
  const start = new Date();

  let days = 14;
  if (duration != null) {
    days = parseInt(duration, 10);
  }
  start.setDate(today.getDate() - days);

  const [tempDateRange, setTempDateRange] = useState({
    from_date: moment(start).format("YYYY-MM-DD"),
    to_date: moment(today).format("YYYY-MM-DD"),
  });

  const [dateRange, setDateRange] = useState({ ...tempDateRange });

  useEffect(() => {
    setDateRange({ ...tempDateRange });
  }, [tempDateRange]);

  const handleStartDateChange = (date) => {
    setTempDateRange({
      ...tempDateRange,
      from_date: date ? moment(date).format("YYYY-MM-DD") : tempDateRange.from_date,
    });
  };

  const handleEndDateChange = (date) => {
    setTempDateRange({
      ...tempDateRange,
      to_date: date ? moment(date).format("YYYY-MM-DD") : tempDateRange.to_date,
    });
  };

  const handleDateUpdate = () => {
    const daysDifference = moment(tempDateRange.to_date).diff(
      moment(tempDateRange.from_date),
      "days"
    );
    if (daysDifference >= 0 && daysDifference <= 21) {
      setDateRange(tempDateRange);
    } else {
      alert("Please select a date range not exceeding 21 days.");
    }
  };

  const start_time = dateRange.from_date + " 00:00:00";
  const end_time = dateRange.to_date + " 00:00:00";

  return (
    <>
      <NavbarBoot />
      <div>
        <div>
          <h2 className="bg-slate-300 text-2xl pl-5">WMATA B-Line</h2>
          <h6 className="bg-slate-300 text-xs pl-5">Temperature & Humidity</h6>
          <div className="date-picker-container">
            <DatePicker
              selected={new Date(tempDateRange.from_date)}
              onChange={handleStartDateChange}
              maxDate={new Date()}
            />
            <DatePicker
              selected={new Date(tempDateRange.to_date)}
              onChange={handleEndDateChange}
              maxDate={new Date()}
            />
            <button onClick={handleDateUpdate}>Update Date Range</button>
          </div>
      <h2 className='bg-slate-300 text-2xl pl-5'>WMATA Tunnels</h2>
      <h6 className='bg-slate-300 text-xs pl-5 pb-2'>Surface Temperature & Humidity</h6>
      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'B-1_523.00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_th1_location",
              scalar:'39.37',
              tare:'0.0'
            },
            

          ],
          secondary: [
            {
              location: 'B-1_523.00',
              name: 'Velocity',
              sensortype: 'velocity',
              table: "leap_th1_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'B-1_523.00 Level and Velocity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 4.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Level (in.)',
          y_axis_label2: 'Velocity (ft/s)',
        }}
      />
      
      <h6 className=''>B-2 Flow</h6>      
      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'B-2_523.00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_th1_location",
              scalar:'39.37',
              tare:'0.0'
            },
            

          ],
          secondary: [
            {
              location: 'B-2_523.00',
              name: 'Velocity',
              sensortype: 'velocity',
              table: "leap_th1_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'B-2_523.00 Level and Velocity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 4.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Level (in.)',
          y_axis_label2: 'Velocity (ft/s)',
        }}
      />
      
      <h6 className='bg-slate-300 text-xs pl-5 pb-2'>A-Line Flow</h6>

      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'A-1_296+00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_th1_location",
              scalar:'39.37',
              tare:'0.0'
            },
            

          ],
          secondary: [
            {
              location: 'A-1_296+00',
              name: 'Velocity',
              sensortype: 'velocity',
              table: "leap_th1_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'A-1_296+00 Level and Velocity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 4.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Level (in.)',
          y_axis_label2: 'Velocity (ft/s)',
        }}
      />
      <PlotData
        url='/api/wmata/gage/'
        gagelist={{
          primary: [
            {
              location: 'A-2_298.00',
              name: 'Level',
              sensortype: 'level',
              table: "leap_th1_location",
              scalar:'39.37',
              tare:'0.0'
            },
            

          ],
          secondary: [
            {
              location: 'A-2_298.00',
              name: 'Velocity',
              sensortype: 'velocity',
              table: "leap_th1_location",
              scalar:'3.937',
              tare:'0.0'
            },],

          y_range: [0, 70],
          title: 'A-2_298.00 Level and Velocity',
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [0.0, 4.0],
          y_axes_range2: [0.0, 1.0],
          y_axis_label: 'Level (in.)',
          y_axis_label2: 'Velocity (ft/s)',
        }}
      />
            


    </div>
    
    </div></>
  )
}

export default PlotFlow2wk
