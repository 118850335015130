import { createContext, useReducer, useEffect } from 'react'
import { jwtDecode } from 'jwt-decode';

// import { projectAuth } from '../firebase/config'

const DJANGO_URL = process.env.REACT_APP_DJANGO


export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload,authIsReady: true }
    case 'LOGOUT':
      return { ...state, user: null,authIsReady: false }
    case 'AUTH_IS_READY':
      return { user: action.payload, authIsReady: true }
    case 'TOKEN_EXPIRED':
      return { ...state, user: null, authIsReady: false }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, { 
    user: null,
    authIsReady: false
  })

  const loginUser = async (username,password) => {
    
    const response = await fetch(`${DJANGO_URL}/api/users/login/`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-type':'application/json'
        },
        body:  JSON.stringify({
            'username':username, 'password':password}),
    })
    
    if(response.status === 200) {
        // window.location = '/status'
        console.log('status200')
    }

    if(response.status === 404) {
        window.location = '/notfound'
    } else {
        const data = await response.json()

        // setUsers(data)
        // setLoading(false)
        dispatch({
            type: 'LOGIN',
            payload: data,
        })
        if(data.access) {
            localStorage.setItem('userInfo', JSON.stringify(data))
            //sessionStorage.setItem('userInfo', JSON.stringify(data))
            const decodedToken = jwtDecode(data.access);
            if (decodedToken.exp < Date.now() / 1000) {
              dispatch({ type: 'TOKEN_EXPIRED' });
            }            
        } else {
            console.log('no access')

        } 
        //localStorage.setItem('userInfo', JSON.stringify(data))
      //   dispatch({
      //     type: 'AUTH_IS_READY',
      //     payload: data,
      // })
    }



}




  console.log('AuthContext state:', state)
  
  return (
    <AuthContext.Provider value={{ ...state, loginUser }}>
      { children }
    </AuthContext.Provider>
  )

}