import NavbarBoot from "../../components/nav/NavbarNew"
import OpenSeadragonViewer from '../../components/seadragon/OpenSeadragonViewer';

function HiRes() {
  return (
    <><NavbarBoot />
    <div>
      <h1>High Resolution Sample</h1>
      <OpenSeadragonViewer />
    </div></>
  );
}

export default HiRes;
