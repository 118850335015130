import NavbarBoot from "../../components/nav/NavbarNew"

import { render } from "@testing-library/react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro"
import dayjs from "dayjs"

import { useAuthContext } from '../../hooks/useAuthContext'

const REACT_DJANGO = process.env.REACT_APP_DJANGO

let url = REACT_DJANGO + "/api/wmata/status/"

const Status = (eventtime) => {
  const {user} = useAuthContext()
  // const { eventtime } = useParams()
  const [data, setData] = useState({})
  const [event, setEvent] = useState({})
  const [isLoading, setLoading] = useState(true)


  // console.log(eventtime.eventtime)

  // console.log(JSON.stringify({'dateRange':[start_time,end_time]}))
  useEffect(() => {

    console.log('inside status')
    console.log(user)
    var userData= JSON.parse(localStorage.getItem('userInfo'))

    const getStatus = async () => {
      
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          'Authorization':'Bearer '+userData.token
        },
      })
      console.log(response)
      const resopnseJson = await response.json()
      console.log("json", resopnseJson)
      setData(resopnseJson)
      setLoading(false)
    }
    getStatus()
  }, [])

  if (isLoading) {
    return <div>loading</div>
  } else {
    return (
      <>
      { console.log(data.status_leap)}
        <NavbarBoot />
        <div className="flex flex-col justify-between ">
          <main className="container mx-auto px-3 pb-12">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <h2>Status</h2>
                  <table>
                    <thead className="bg-white border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap"
                        >
                          System
                        </th>
                        <th
                          scope="col"
                          className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap"
                        >
                          Sensor Type
                        </th>
                        <th
                          scope="col"
                          className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap"
                        >
                          Last Data Time
                        </th>
                      </tr>

                      {data && (data.status_leap.map((item,i) => (
                        <tr>
                          <td key={item.location}
                            scope="col"
                            className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap"
                          >
                            {item.location}
                          </td>
                          <td key={item.i}
                            scope="col"
                            className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap"
                          >
                            {item.sensortype}
                          </td>
                          <td key={item.last_timestamp}
                            scope="col"
                            className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap"
                          >
                            {item.last_timestamp}
                          </td>
                        </tr>
                      ) ))}
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    )
  }
}

export default Status
